import React from "react";
import { isEmpty, get, pickBy, identity, has } from "lodash";
import { navigate } from "gatsby"
import { withSubtheme } from '../../../../StarberryComponentsMui';
import GlobalForm from "../../forms/";
import { collectFormValues, validateFormFields } from "../../forms/utils";
import { useProfileState } from "../../../../services";
import savedSearchFields from "../../forms/Forms/propertyPreferences";
import defaults from "./dialogContentDefaults";
import GlobalConversationalForm from "../../conversationalforms"
import registerPropertyPreferences from "../../conversationalforms/forms/registerPropertyPreference"
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import Alert from "../../alert"
import { useAuthState } from "../../../../services"
import { getUser } from "../../../../services/store/utils"
import { useSelector } from 'react-redux';
import { trackPersonaUpdate, trackValuation, trackPropertyPref } from "../../helper/eventTracking";

const getUserId = (user) => {
    return user?.profile?.id || user?.profile?.uid || user?.user?.id || user?.user?.uid || ''
}
const getPrefData = (value, key='label') => {
    if ( typeof value === 'object' ) {
        if (!isEmpty(value) && value.length) {
            if (key === 'area') {
                let label = 'label';
                if (value[0]?.name)
                    label = 'name'

                return value.map(option => option[label]).join(',')
            }
            if (value[0]?.key)
                return value.map(option => option[key]).join(',')
        }
        return ``
    }

    if (value === 'any')
        return '';
    return value
}

const DialogChildrenRegularForm = withSubtheme((props) => {
    const collectUserDetails = (state) => {
        if (state?.profile || state?.user) {
            return {
                profile_forename: state?.profile?.forename || state?.user?.name || null,
                profile_surname: state?.profile?.surname || state?.user?.surname || null,
                profile_mobile: state?.profile?.mobile || state?.user?.mobile || null,
            }
        }

        if (state?.user) {
            return {...state.user, forename: state.user.name}
        }
        return {}
    }
    const { className } = props
    const { state, services } = useProfileState();
    const user = useSelector(state => state.profile);
    let customFields = [...savedSearchFields];
    let formStateType = collectUserDetails(user);
    if (formStateType?.property_preference) {
        formStateType = {...formStateType, ...formStateType.property_preference}
        if (formStateType.property_preference.search_type) {
            customFields.map((field, key) => {
                if (field.name === 'minPrice' || field.name === 'maxPrice') {
                    customFields[key].defaultKey = formStateType.property_preference.search_type;
                }
                return true;
            })
        }
    }

    const [formFields, setFormFields ] = React.useState([...customFields]);
    const ref = React.useRef(null)
    let message = state?.message;
    let formError = state?.error;
    const messageDialog = {message:message, severity:formError ? 'error' : 'success'}
    const formData = { submitLabel: "Submit" }
    const hideForm = (state.preference_success && !state.error)

    const onSubmit = event => {
        event.preventDefault()
        const isBrowser = typeof window !== "undefined"
        if (isBrowser) {
            window.scrollTo(0, 0)
        }
        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);

        if (ref.current.checkValidity() !== false) {
            const params = {
                id: state.user.uid,
                successMessage: "Property Preferences updated Successfully",
            }
            const values = collectFormValues(ref, params)
            services.updatePropertyPreference({ ...values })
            trackPropertyPref();
        }
    }

    return (

            <div className={className} style={{"position": "relative"}}>
                <GlobalForm
                    formFields={formFields}
                    handleSubmit={onSubmit}
                    formStateType={formStateType}
                    messageDialog={messageDialog}
                    data={formData}
                    ref={ref}
                    hideForm={hideForm}
                />
            </div>

    );

}, 'dialogChildren', defaults);

const DialogChildrenConversationalForm = withSubtheme((props) => {
    const collectUserDetails = (state) => {
        if (state?.profile || state?.user) {
            return {
                profile_forename: state?.profile?.forename || state?.user?.name || null,
                profile_surname: state?.profile?.surname || state?.user?.surname || null,
                profile_mobile: state?.profile?.mobile || state?.user?.mobile || null,
                profile_newsAndUpdatesSubscription: state?.profile?.newsAndUpdatesSubscription || state?.user?.newsAndUpdatesSubscription || null,
            }
        }

        if (state?.user) {
            return {...state.user, forename: state.user.name}
        }
        return {}
    }
    const { className } = props
    const { state: profileState, services: profileServices } = useProfileState();
    const { services: authServices } = useAuthState();

    const user = useSelector(state => state.profile);
    let formStateType = collectUserDetails(user);

    const formVisible = !profileState.complete_profile_success && !profileState.error;

    const handleSubmit = React.useCallback(async (values) => {
        const userData = getUser();
        const isBrowser = typeof window !== "undefined"
        if (isBrowser) {
            window.scrollTo(0, 0)
        }

        try {
            const payload = {
                id: getUserId(user),
                createAlert: values.createAlert,
                bookValuation: values.bookValuation,
                successMessage: "Profile updated Successfully",
            };

            payload.profile = {
                profile_forename: values.profile_forename,
                // profile_newsAndUpdatesSubscription: values.profile_newsAndUpdatesSubscription,
                profile_mobile: values.profile_mobile,
                profile_mobileCountry: values.profile_mobileCountry,
                profile_persona: values.profile_persona,
                profile_surname: values.profile_surname,
                profile_property_address: values?.profile_address || values?.profile_address_rawAddress,
                mobile: values.profile_mobile,
                name: values.profile_forename,
                forename: values.profile_forename,
                surname: values.profile_surname,
                email: userData.email,
                // newsAndUpdatesSubscription: values.profile_newsAndUpdatesSubscription,
                propertyToSell: values?.propertyToSell,
                propertyOnMarket: values?.propertyOnMarket,
                fundingPurchase: values?.fundingPurchase,
                propertyMortgage: values?.propertyMortgage,
            };

            if (has(values, 'profile_newsAndUpdatesSubscription', false)) {
                payload.profile['profile_newsAndUpdatesSubscription'] = values.profile_newsAndUpdatesSubscription
                payload.profile['newsAndUpdatesSubscription'] = values.profile_newsAndUpdatesSubscription
            }

            // try to collect the address details from profile address
            if (values.profile_address) {
                if (!user?.profile?.address1 && !user?.profile?.address1) {
                    let altAddress = values.profile_address.split('\n');
                    altAddress = altAddress.filter(x => x)
                    if (altAddress.length > 1) {
                        payload.profile.address1 = get(altAddress, '0', '')
                        payload.profile.address2 = get(altAddress, '1', '')
                        payload.profile.town = get(altAddress, '2', '')
                        payload.profile.postcode = get(altAddress, '3', '')

                        payload.profile.profile_address1 = get(altAddress, '0', '')
                        payload.profile.profile_address2 = get(altAddress, '1', '')
                        payload.profile.profile_town = get(altAddress, '2', '')
                        payload.profile.profile_postcode = get(altAddress, '3', '')
                    } else {
                        altAddress = values.profile_address.split(',');
                        altAddress = altAddress.filter(x => x)

                        payload.profile.address1 = get(altAddress, '0', '')
                        payload.profile.address2 = get(altAddress, '1', '')
                        payload.profile.town = get(altAddress, '2', '')
                        payload.profile.postcode = get(altAddress, '3', '')

                        payload.profile.profile_address1 = get(altAddress, '0', '')
                        payload.profile.profile_address2 = get(altAddress, '1', '')
                        payload.profile.profile_town = get(altAddress, '2', '')
                        payload.profile.profile_postcode = get(altAddress, '3', '')
                    }
                }
            }

            if (values?.profile_address_rawAddress) {
                payload.profile.address1 = values?.profile_address_line1
                payload.profile.address2 = values?.profile_address_line2
                payload.profile.postcode = values?.profile_address_postcode

                payload.profile.profile_address1 = values?.profile_address_line1
                payload.profile.profile_address2 = values?.profile_address_line2
                payload.profile.profile_postcode = values?.profile_address_postcode
            }

            if (['tenant', 'buyer'].includes(values.profile_persona) /*&&
                values.createAlert === 'yes'*/) {

                let preferenceAlert = {};
                if (values.createAlert === 'yes') {
                    preferenceAlert = {
                        preferenceAlert_areas: getPrefData(values.preference_areas, 'area'),
                        preferenceAlert_areas_ids: getPrefData(values.preference_areas, 'value'),
                        preferenceAlert_bedroom: values.preference_bedroom,
                        preferenceAlert_building: getPrefData(values.preference_building),
                        preferenceAlert_building_ids: getPrefData(values.preference_building, 'value'),
                        preferenceAlert_maxPrice: values.preference_maxPrice,
                        preferenceAlert_minPrice: values.preference_minPrice,
                        preferenceAlert_search_type: values.createAlert === 'yes' ? (values.profile_persona === 'buyer' ? 'sales' : 'lettings') : '',
                    };

                    let prefData = pickBy(preferenceAlert, identity);

                    if (isEmpty(prefData)) {
                        preferenceAlert = {};
                    } else {
                        preferenceAlert = prefData;
                    }
                }

                payload.preferences = {
                    preference_persona: values.profile_persona,
                    // preference_newsAndUpdatesSubscription: values.profile_newsAndUpdatesSubscription ? "yes" : "no",
                    preference_address: values.profile_address,
                    preference_createAlert: values.createAlert,
                    preference_propertyToSell: values.propertyToSell,
                    preference_propertyOnMarket: values.propertyOnMarket,
                    preference_fundingPurchase: values.fundingPurchase,
                    preference_propertyMortgage: values.propertyMortgage,
                    // Addtional questions
                    preference_sellingPosition: values?.profile_sellingPosition,
                    preference_sellingStatus: values?.profile_sellingStatus,
                    preference_buyingPosition: values?.profile_buyingPosition,
                    preference_buyingReason: values?.profile_buyingReason,
                    preference_areas: getPrefData(values.preference_areas, 'area'),
                    preference_areas_ids: getPrefData(values.preference_areas, 'value'),
                    preference_bedroom: values.preference_bedroom,
                    preference_building: getPrefData(values.preference_building),
                    preference_building_ids: getPrefData(values.preference_building, 'value'),
                    preference_maxPrice: values.preference_maxPrice,
                    preference_minPrice: values.preference_minPrice,
                    preference_search_type: values.createAlert === 'yes' ? (values.profile_persona === 'buyer' ? 'sales' : 'lettings') : '',
                    ...preferenceAlert
                };

                if (has(values, 'profile_newsAndUpdatesSubscription', false)) {
                    payload.preferences['preference_newsAndUpdatesSubscription'] = values.profile_newsAndUpdatesSubscription ? "yes" : "no"
                }

                let preferencesData = pickBy(payload.preferences, identity);

                if (isEmpty(preferencesData)) {
                    payload.preferences = '';
                } else {
                    payload.preferences = preferencesData;
                }

                trackPropertyPref();
            }


            if (['landlord', 'seller', 'both'].includes(values.profile_persona) && values.bookValuation === 'yes') {
                payload.valuation = {
                    form_name: "valuation",
                    buildingName: values.valuation_address_buildingName,
                    builderNumber: values.valuation_address_buildingNumber,
                    countryId: values.valuation_address_countryId,
                    line1: values.valuation_address_line1,
                    line2: values.valuation_address_line2,
                    postalCode: values.valuation_address_postcode,
                    postcode: values.valuation_address_postcode,
                    property_address: values.valuation_address_rawAddress || values.valuation_address,
                    property_type: values.profile_persona === 'seller' ? 'sales' : 'sales',
                };

                if (!values.preferredTimeslotVisible) {
                    payload.valuation.appointment_time = format(values.appointment_time, 'yyyy-MM-dd HH:mm');
                    payload.valuation.duration = values.valuation_duration;
                    payload.valuation.start_time = format(values.appointment_time, 'HH:mm');
                    payload.valuation.end_time = format(addMinutes(values.appointment_time, values.valuation_duration), 'HH:mm');
                    payload.valuation.office_id = values.valuation_officeId;
                    payload.valuation.negotiator_id = values.valuation_negotiatorId;

                    // Track Valuation
                    trackValuation({
                        eventLabel: values.profile_persona === 'seller' ? 'sales' : 'sales'
                    })

                } else {
                    payload.valuation.message = values.valuation_message;
                }
            }

            await profileServices.completeProfile(payload);
            authServices.updatePersona(payload.profile);

            // For form persona update
            trackPersonaUpdate({
                eventLabel: values.profile_persona
            })

        } catch (err) {
            console.error(err);
        }
    }, [profileState, profileServices.completeProfile]) // eslint-disable-line react-hooks/exhaustive-deps

    const initialValuesDepArr = [
        formStateType?.profile_forename,
        formStateType?.profile_surname,
        formStateType?.profile_mobile,
        formStateType?.property_preference,
        formStateType?.property_preference?.search_type
    ]
    const initialValues = React.useMemo(() => {
        let result = {
            profile_forename: formStateType?.profile_forename || '',
            profile_surname: formStateType?.profile_surname || '',
            profile_mobile: formStateType?.profile_mobile || ''
        };

        if (has(formStateType, 'profile_newsAndUpdatesSubscription', false)) {
            result['profile_newsAndUpdatesSubscription'] = formStateType?.profile_newsAndUpdatesSubscription;
        }

        if (formStateType?.property_preference) {
            result = {
                ...result,
                ...formStateType?.property_preference,
            };

            if (formStateType.property_preference) {
                result = {
                    ...result,
                    createAlert: 'yes',
                    preference_minPrice: formStateType.property_preference.minPrice,
                    preference_maxPrice: formStateType.property_preference.maxPrice,
                    preference_bedroom: formStateType.property_preference.bedroom,
                    profile_persona: formStateType.property_preference.ptype,
                };
            }
        }

        return result;
    }, initialValuesDepArr); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (profileState.complete_profile_success && !profileState.error) {
            setTimeout(async () => {
                await navigate('/');
            }, 1000);
        }
    }, [profileState.complete_profile_success, profileState.error]);

    return (
        <div className={className} style={{"position": "relative"}}>
            {formVisible && (
                <GlobalConversationalForm
                    initialValues={initialValues}
                    form={registerPropertyPreferences}
                    onSubmit={handleSubmit}
                />
            )}
            {profileState?.message && (
                <Alert
                    type={formVisible ? 'alert' : ''}
                    message={profileState?.message}
                    severity={profileState?.error ? 'error' : 'success'}
                />
            )}
        </div>

    );

}, 'dialogChildren', defaults);

const DialogChildren = ({ conversationalFormEnabled }) => {
    if (conversationalFormEnabled) {
        return <DialogChildrenConversationalForm />;
    } else {
        return <DialogChildrenRegularForm />;
    }
};

export default DialogChildren;
